import React from 'react';
import Layout from '../components/Layout';
import { Typography } from '@material-ui/core';

const Thanks = () => {
  return (
    <Layout pageName="Thanks">
      <Typography variant="h1">Merci&#160;! </Typography>
      <Typography variant="h2">On te répond au plus vite&#160;;)</Typography>
    </Layout>
  );
};
export default Thanks;